@import 'variables';
@import 'breakpoints';

.App {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: var(--fontSize);

  > div:not(.Nav) {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: all var(--transitionSpeed) ease;
  }

  // Page transitions
  > div.fade-enter {
    transition: opacity var(--transitionSpeed);
    transition-timing-function: ease-out;
    opacity: 0;
  }

  > div.fade-enter.fade-enter-active {
    opacity: 1;
    z-index: 1;
  }

  > div.fade-exit {
    transition: opacity var(--transitionSpeed);
    transition-timing-function: ease-in;
    opacity: 1;
  }

  > div.fade-exit.fade-exit-active {
    opacity: 0;
    z-index: 0;
  }
}
