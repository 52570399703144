@import 'variables';
@import 'breakpoints';
@import 'typography';

div.Colophon {
  position: fixed;
  top: 0;
  left: 50%;

  transform: translateX(-50%);
  width: 100%;
  overflow-y: auto;
  line-height: 0.875em;
  background-color: white;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      @extend .link;
    }
  }

  .text,
  .press,
  .buy,
  .credits,
  section {
    width: $maxWidth;
  }

  .tea-towel {
    width: 100%;
  }

  section {
    display: block;
    letter-spacing: 0.0125em;
    box-sizing: border-box;

    .presskit {
      margin-bottom: 1.1em;
    }

    .presskit-icon {
      height: 1.5em;
      transform: translate(0, 35%);
      margin-right: 0.75em;
    }

    p {
      margin-top: 0;
      margin-bottom: 0.75em;
    }

    .caption {
      font-size: 0.85em;
      line-height: 1.2em;
    }

    &:nth-child(1) {
      margin-top: $margin * 1.5;
      font-size: $textSize;
    }

    &:last-of-type {
      padding-top: $padding / 2;
      padding-bottom: $margin * 3.5;
    }

    &.footer {
      font-size: $textSize;

      > div {
        margin: 0.3em auto;
      }

      .credits {
        font-size: 0.85em;
        line-height: 1.3em;
      }

      .logos {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 10px;

        a {
          margin-right: 30px;
          box-shadow: none;
        }

        svg {
          height: auto;
          fill: $grey;
        }

        svg.hkw {
          width: 84px;
        }
        svg.mozilla {
          width: 94px;

          path:nth-child(2) {
            fill: white;
          }
        }
      }
    }
  }

  @include breakpoint(tablet) {
    $margin-tablet: calc(#{$margin} - 10px);
    $padding-tablet: calc(#{$padding} - 10px);
    font-size: 0.9em;

    section {
      padding: 0 $padding-tablet;
      width: 100% !important;
      max-width: 100% !important;

      > div {
        width: 100% !important;
        max-width: 100% !important;
      }

      &.footer {
        > div {
          margin: 0 auto;
        }

        .credits {
          font-size: 0.88em;
          line-height: 1.3em;
        }
      }
    }

    .settings {
      bottom: calc(#{$margin-tablet} + 4px);
      right: $margin-tablet;
    }

    .container {
      width: calc(100% - #{$padding-tablet} * 2);
    }
  }

  @include breakpoint(laptop) {
    section.footer .logos {
      margin-top: 40px;

      a {
        margin-right: 50px;
      }

      svg {
        &.hkw {
          width: 140px;
        }
        &.mozilla {
          width: 165px;
        }
      }
    }
  }
}
