@import 'variables';
@import 'breakpoints';

.Pencil {
  position: absolute;
  top: 0;
  left: 0;
  width: 125px;
  height: 125px;
  background-image: url('/assets/images/pencil.png');
  background-position: center;
  background-size: cover;
  pointer-events: none;
  transform: translate(var(--x), var(--y));
  opacity: 1;
  transition: opacity 100ms;
  z-index: 999;

  &.hidden {
    opacity: 0;
  }

  // @include breakpoint(tablet) {
  //   width: 90px;
  //   height: 90px;
  //   transform: translate(calc(var(--x) + 90px), calc(var(--y) + 35px));
  // }
}
