// Globals
$margin: 30px;
$padding: 30px;
$maxWidth: 650px;

$textSize: 0.71em;
$lineHeight: 1em;
$letterSpacing: 0.05em;

$buttonSize: 50px;
$checkerSize: 20px;

$grey: #505151;
$red: #e2574c;