@import 'variables';
@import 'mixins';

@font-face {
  font-family: 'Vizner';
  src: url('/assets/fonts/vizner-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/vizner-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  font-family: 'Vizner', sans-serif;
  text-rendering: geometricPrecision;
  letter-spacing: $letterSpacing;
  color: $grey;
}

.link {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  @include link($grey);
}
