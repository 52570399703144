@import 'variables';
@import 'typography';
@import 'buttons';

html, body {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
  overflow: hidden !important;
  outline: none !important;
  -webkit-overflow-scrolling: touch;
}

body {
  &::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url('/assets/images/background.png');
    background-size: 20px 20px;
    image-rendering: pixelated;
    overflow: hidden;
    pointer-events: none;
  }
}
