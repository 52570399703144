@import 'variables';
@import 'breakpoints';

.Home {
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  >.button {
    position: absolute;
    top: calc(#{$margin * 1.5} + #{$buttonSize} - 4px);
    right: calc(#{$margin} - 2.5px);

    &:active {
      transform: translateY(2.5px);
      height: calc(#{$buttonSize} - 2.5px);
    }
  }

  @include breakpoint(tablet) {
    >.button {
      position: absolute;
      top: initial;
      right: initial;
      left: calc(#{$margin * 1.5} + #{$buttonSize});
      bottom: calc(#{$margin} - 5px);
    }
  }
}