@import 'variables';
@import 'breakpoints';

.Eraser {
  position: absolute;
  top: $margin * 4.1;
  left: $margin * 2.75;
  width: calc(115px * (288 / 455));
  height: 115px;
  background-image: url('/assets/images/eraser-vertical.png');
  background-position: center;
  background-size: cover;
  cursor: pointer;
  transform: translate(var(--x), var(--y)) rotate(45deg);
  transform-origin: center;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active, &:hover {
    transform: translate(var(--x), var(--y)) scale(var(--scale)) rotate(45deg);
    transition: transform 75ms;
  }

  &.erasing {
    transition: none;
    pointer-events: none;
    transform: translate(var(--x), var(--y)) rotate(45deg);
  }

  @include breakpoint(tablet) {
    top: $margin * 5.4;
    left: $margin * 4.4;
    width: calc(85px * (288 / 455));
    height: 85px;
  }
}
