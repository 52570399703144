@import 'variables';
@import 'breakpoints';
@import 'mixins';

.ShopLink {
  @include bottom-text();

  .link {
    display: inline-block;
    @include link($red);
  }

  @include breakpoint(tablet) {
    @include top-text-tablet();
  }
}
