@import 'breakpoints';
@import 'variables';

@mixin top-text() {
  @include top-text-pc();

  @include breakpoint(tablet) {
    @include top-text-tablet();
  }
}

@mixin top-text-tablet() {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  $padding: calc(#{$padding} - 17px);
  width: 100%;
  padding: $padding;
  font-size: 33px;
  line-height: 1.1em;
}

@mixin top-text-pc() {
  pointer-events: none;
  user-select: none;
  outline: none;
  position: fixed;
  top: 0;
  left: 50%;
  width: calc(100% - #{($padding * 2) + ($buttonSize * 2)});
  max-width: 650px;
  transform: translateX(-50%);
  padding: $padding 0;
  box-sizing: border-box;
  z-index: 10;
  text-align: center;
  line-height: 1.1em;
  font-size: 1em;
}

@mixin bottom-text() {
  pointer-events: none;
  position: fixed;
  outline: none;
  user-select: none;
  bottom: 0;
  margin-bottom: calc(#{$margin / 2});
  left: 50%;
  max-width: 1200px;
  width: calc(100% - #{($padding * 2) + ($buttonSize * 6)});
  transform: translateX(-50%);
  box-sizing: border-box;
  text-align: center;

  font-size: 1em;
  line-height: 1.1em;
  color: $red;
}

@mixin drop-shadow-ms() {
  @supports (display: -ms-grid) {
    filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 1));

    &:active {
      filter: drop-shadow(0px 0.5px 0px rgba(0, 0, 0, 1));
    }
  }
}

@mixin link($color: $grey) {
  pointer-events: all;
  text-decoration: none;
  color: $color;

  box-shadow: 0 0.1em 0 -0.025em $color;
  padding-bottom: 0.03em;

  &:visited {
    color: $color;
  }
}
