@import 'breakpoints';
@import 'variables';

.Nav {
  position: fixed;
  top: calc(#{$margin} - 4px);
  right: $margin;
  user-select: none;
  z-index: 1;

  @include breakpoint(tablet) {
    $margin: calc(#{$margin} - 10px);
    top: initial;
    right: initial;
    left: $margin;
    bottom: calc(#{$margin} + 4px);
  }
}