@import 'variables';
@import 'breakpoints';
@import 'mixins';

a.button,
span.button,
button.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: block;
  width: $buttonSize;
  height: $buttonSize;

  &.back {
    background-image: url('/assets/images/arrow_left.svg'),
      url('/assets/images/clicked_arrow_left.svg');
    background-size: contain, 0;
    background-repeat: no-repeat, no-repeat;
    @include drop-shadow-ms();

    &:active {
      background-image: url('/assets/images/clicked_arrow_left.svg');
      height: calc(#{$buttonSize} - 2.5px);
      transform: translateY(2.5px);
    }
  }

  &.arrow-right {
    background-image: url('/assets/images/arrow_right.svg'),
      url('/assets/images/clicked_arrow_right.svg');
    background-size: contain, 0;
    background-repeat: no-repeat, no-repeat;
    @include drop-shadow-ms();

    &:active {
      background-image: url('/assets/images/clicked_arrow_right.svg');
      height: calc(#{$buttonSize} - 2.5px);
      transform: translateY(2.5px);
    }
  }

  &.mute-on {
    background: url('/assets/images/mute_button_off.svg'), url('/assets/images/mute_button_on.svg'),
      url('/assets/images/clicked_mute_button_off.svg');
    background-size: contain, 0, 0;
    background-repeat: no-repeat, no-repeat, no-repeat;
    @include drop-shadow-ms();

    &:active {
      background-image: url('/assets/images/clicked_mute_button_off.svg'),
        url('/assets/images/clicked_mute_button_on.svg');
      height: calc(#{$buttonSize} - 2.5px);
    }
  }

  &.mute-off {
    background: url('/assets/images/mute_button_on.svg'), url('/assets/images/mute_button_off.svg'),
      url('/assets/images/clicked_mute_button_on.svg');
    background-size: contain, 0, 0;
    background-repeat: no-repeat, no-repeat, no-repeat;
    @include drop-shadow-ms();

    &:active {
      background-image: url('/assets/images/clicked_mute_button_on.svg'),
        url('/assets/images/clicked_mute_button_off.svg');
      height: calc(#{$buttonSize} - 2.5px);
    }
  }

  &.colophon {
    background-image: url('/assets/images/question_mark.svg'),
      url('/assets/images/clicked_question_mark.svg');
    background-size: contain, 0;
    background-repeat: no-repeat, no-repeat;
    @include drop-shadow-ms();

    &:active {
      background-image: url('/assets/images/clicked_question_mark.svg');
      height: calc(#{$buttonSize} - 2.5px);
      transform: translateY(2.5px);
    }
  }

  &.debug-on {
    position: relative;
    top: 0.26em;
    width: 1em;
    height: 1em;
    display: inline-block;
    background: url('/assets/images/D_button_on.svg'), url('/assets/images/D_button_off.svg');
    background-size: contain, 0, 0;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

  &.facebook {
    background-image: url('/assets/images/facebook_icon.svg');
  }

  &.twitter {
    background-image: url('/assets/images/twitter_icon.svg');
  }

  &.close {
    background-image: url('/assets/images/close.svg');
  }

  @include breakpoint(tablet) {
    $buttonSize: 55px;
    width: $buttonSize;
    height: $buttonSize;

    &.back,
    &.arrow-right,
    &.mute-on,
    &.mute-off,
    &.colophon {
      &:active {
        transform: translateY(2.5px);
        height: calc(#{$buttonSize} - 2.5px);
      }
    }
  }
}

a.button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
