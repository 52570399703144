@mixin breakpoint($size) {
  @if $size == iphone {
    @media (max-width: 480px) { @content; }
  }
  @else if $size == tablet {
    @media (max-width: 768px) { @content; }
  }
  @else if $size == laptop {
    @media (min-width: 1024px) { @content; }
  }
  @else if $size == desktop {
    @media (min-width: 1280px) { @content; }
  }
}
